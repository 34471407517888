<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Bestseller'">
        <template slot="content" slot-scope="{ setLoading }">
          <div class="mb-3">
            <small>{{ $t("small_description_statistics_bestseller") }}.</small>
          </div>
          <BestsellerSale @loading="setLoading" @setData="setData" />
          <DataTable
            v-if="show"
            :manualColumns="manualColumns"
            :url="url"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import BestsellerSale from "@/components/Statistics/Sales/Bestseller/BestsellerSale.vue";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "BestsellerSaleStatistics",
  components: {
    Ibox,
    BestsellerSale,
    DataTable
  },
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      selectedCategoryId: null,
      show: false,
      manualColumns: [
        {
          key: "id",
          name: "ID",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "name",
          name: "Nazwa",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "quantity",
          name: "Ilość",
          show: true,
          type: "",
          filter: ""
        }
      ]
    };
  },
  computed: {
    url() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/sales/bestsellers?dateStart=${this.dateStart}&dateEnd=${this.dateEnd}&categoryId=${this.selectedCategoryId}`;
    }
  },
  methods: {
    setData(dateStart, dateEnd, selectedCategoryId) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
      this.selectedCategoryId = selectedCategoryId;
      this.show = true;
      this.$forceUpdate();
    }
  }
};
</script>
