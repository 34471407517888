<template>
  <div class="row">
    <div class="col">
      <div class="form-group row">
        <div class="col-12 col-md-4">
          <label>
            {{ $t("categories") }} ({{ $t("optional").toLowerCase() }})
          </label>
          <v-select
            multiple
            name="categoryId"
            v-model="selectedCategoryId"
            label="name"
            :options="categoryList"
            :reduce="category => category.id"
          ></v-select>
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div
              class="col-12 col-sm-6"
              :class="{ 'has-error': errors.has('date_start') }"
            >
              <label>
                {{ $t("start_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="datePickerStart"
                :config="optionsDate"
                name="date_start"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('date_start')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
            <div
              class="col-12 col-sm-6"
              :class="{ 'has-error': errors.has('date_end') }"
            >
              <label>
                {{ $t("end_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="datePickerEnd"
                :config="optionsDate"
                name="date_end"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('date_end')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          <div class="btn btn-primary ml-1 mb-1" @click="setTime(30)">
            {{ $t("last_month") }}
          </div>
          <div class="btn btn-primary ml-1 mb-1" @click="setTime(90)">
            {{ $t("last_3_months") }}
          </div>
          <div class="btn btn-primary ml-1 mb-1" @click="setTime(180)">
            {{ $t("last_6_months") }}
          </div>
          <div class="btn btn-primary ml-1 mb-1" @click="setTime(365)">
            {{ $t("last_12_months") }}
          </div>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button @click="submitForm()" class="btn btn-primary btn-sm">
            {{ $t("download_data") }}
          </button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
    </div>
  </div>
</template>
<script>
import qs from "qs";
export default {
  name: "BestsellerSale",
  data() {
    return {
      selectedCategory: null,
      datePickerStart: null,
      datePickerEnd: null,
      categoryList: [],
      selectedCategoryId: []
    };
  },
  computed: {
    urlCategoryList() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/categories`;
    }
  },
  created() {
    this.getCategoryList();

    this.$route.query.dateStart
      ? (this.datePickerStart = this.$moment(
          this.$route.query.dateStart
        ).format("YYYY-MM-DD"))
      : (this.datePickerStart = this.$moment(this.dateEnd)
          .subtract(1, "months")
          .format("YYYY-MM-DD"));

    this.$route.query.dateEnd
      ? (this.datePickerEnd = this.$moment(this.$route.query.dateEnd).format(
          "YYYY-MM-DD"
        ))
      : (this.datePickerEnd = this.$moment().format("YYYY-MM-DD"));

    if (this.$route.query.categoryId) {
      let str = this.$route.query.categoryId;
      if (str.length > 1) {
        this.selectedCategoryId = str.split(",").map(el => Number(el));
      } else this.selectedCategoryId.push(Number(str));
    }
  },
  methods: {
    getCategoryList() {
      this.$emit("loading", true);
      return this.$http.get(this.urlCategoryList).then(resp => {
        if (resp.status === 200) {
          this.categoryList = resp.data.data;
          this.$emit("loading", false);
          return true;
        }
        this.$emit("loading", false);
      });
    },
    setTime(time) {
      let months = Math.round(time / 30);
      this.datePickerEnd = this.$moment().format("YYYY-MM-DD");
      this.datePickerStart = this.$moment(this.datePickerEnd)
        .subtract(months, "months")
        .format("YYYY-MM-DD");
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.setData();
        }
      });
    },
    async setData() {
      let query = {};
      if (window.location.search != "") {
        let queryArr = window.location.search.split("?")[1].split("&");
        queryArr.forEach(el => {
          let param = el.split("=");
          query[param[0]] = param[1];
        });
      }
      query.dateStart = this.datePickerStart;
      query.dateEnd = this.datePickerEnd;
      query.categoryId = this.selectedCategoryId.join();
      let url = `${this.$route.path}?${qs.stringify(query, {
        offset: 0,
        encode: false
      })}`;
      await history.pushState({}, null, url);
      this.$emit(
        "setData",
        this.datePickerStart,
        this.datePickerEnd,
        this.selectedCategoryId
      );
    }
  }
};
</script>
